// Load the AWS SDK for Node.js
let AWS = require('aws-sdk');
// Set the region 
AWS.config.update({
    region: 'us-east-1', 
    secretAccessKey:'qSfDiWez2eK5hDNkMaMNgPKLEk55yaO+ss9xqIkK', 
    accessKeyId:'AKIAZ4FGYLINY6QVFJUH'
});
  
// Create the DynamoDB service object
let docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2012-08-10', region_name: 'us-east-1'});

const tableName = 'nimloth_waitlist_emails'

export const fetchData = (tableName) => {
    let params = {
        TableName: tableName
    }

    docClient.scan(params, function (err, data) {
        if (!err) {
            console.log(data)
        }
    })
}

export const putData = (tableName , data) => {
    let params = {
        TableName: tableName,
        Item: {
            userEmail: data
        }
    }
    
    docClient.put(params, function (err, data) {
        if (err) {
            console.log('Error', err)
        } else {
            console.log('Success', data)
        }
    })
}